import { UiSchema } from "@rjsf/utils";
import * as _ from "lodash";

import FormSchema from "../../models/schema/FormSchema";
import FirebaseService from "../firebase/FirebaseActions";

class FormSchemaActions {
  public async getFormSchema(schemaEndpoint: string): Promise<FormSchema> {
    try {
      const response = await fetch(schemaEndpoint);
      return await response.json();
    } catch (error) {
      console.error("Form schema could not be found. Error: ", error);
      throw error;
    }
  }

  public async getFormSchemaByUserId(userId: string): Promise<FormSchema> {
    const userData = await FirebaseService.getUserData(userId);
    const formSettings = userData.settings.formSettings;

    if (!formSettings.formSchema) {
      throw new Error(`No form schema found for user ${userId} found`);
    }
    return formSettings.formSchema;
  }

  public getLimitProperties(uiSchema: UiSchema): string[] {
    return Object.entries(uiSchema)
      .map(([key, value]): string | null => {
        if (_.isEqual(value, { "ui:widget": "limitedCheckbox" })) {
          return key;
        }
        return null;
      })
      .filter((value) => value !== null)
      .map((value) => value as string);
  }
}

export default new FormSchemaActions();
