import { UiSchema } from "@rjsf/utils";

export const UpdateEmailUiSchema: UiSchema = {
  currentEmail: {
    "ui:widget": "email"
  },
  currentPassword: {
    "ui:widget": "password"
  },
  newEmail: {
    "ui:widget": "email"
  }
};
