import { UiSchema } from "@rjsf/utils";

export const UpdatePasswordUiSchema: UiSchema = {
  currentPassword: {
    "ui:widget": "password"
  },
  password: {
    "ui:widget": "password"
  },
  passwordConfirmation: {
    "ui:widget": "password"
  }
};
