import { UiSchema } from "@rjsf/utils";

import { wysiwygWidget } from "../settings/widget/wysiwygWidget";
import { SubscriptionType } from "../../models/subscription/SubscriptionType";

export const FormBuilderUiSchema = (subscriptionType: SubscriptionType): UiSchema => {
  const disabledFields: string[] = ["divider"];
  if (!subscriptionType) {
    disabledFields.push("title", "markdown", "email", "integer", "boolean", "number");
  }

  return {
    formFields: {
      items: {
        markdownDescription: {
          "ui:widget": wysiwygWidget
        },
        fieldType: {
          "ui:enumDisabled": disabledFields
        }
      }
    }
  };
};
