import React, { FunctionComponent } from "react";
import { toast } from "react-toastify";
import validator from "@rjsf/validator-ajv8";
import { Form } from "@rjsf/bootstrap-4";

import FirebaseService from "../../actions/firebase/FirebaseActions";
import { PrimaryButton } from "../common/button/Buttons";
import { EmailSettingsSchema } from "./EmailSettingsSchema";
import { EmailSettingsUiSchema } from "./EmailSettingsUiSchema";
import { MailSettings } from "../../models/userData/settings/MailSettings";
import { useAuthUser } from "../../hooks/useAuthUser";
import { EmailVerifiedNotification } from "../common/EmailVerifiedNotification";
import { trackEvent } from "../../helper/analytics";

interface Props {
  userId: string;
  emailSettings?: MailSettings;
}
const EmailSettings: FunctionComponent<Props> = ({ userId, emailSettings }: Props) => {
  const user = useAuthUser();
  const emailVerified = user?.emailVerified;

  if (!emailSettings) {
    return <p>E-Mail-Einstellungen konnten nicht geladen werden. Bitte wenden Sie sich an den Support.</p>;
  }

  const updateEmailSettings = (formData: unknown): void => {
    const updatedEmailSettings = formData as MailSettings;

    FirebaseService.updateEmailSettings(userId, updatedEmailSettings)
      .then(() => {
        toast.success("Die Einstellungen wurden erfolgreich gespeichert.");
      })
      .catch((error) => {
        toast.error(`Fehler: ${error}`);
      })
      .finally(() => {
        trackEvent("update-email-settings");
      });
  };

  return (
    <div>
      <EmailVerifiedNotification user={user} />
      {emailVerified && (
        <Form
          validator={validator}
          formData={emailSettings}
          schema={EmailSettingsSchema}
          uiSchema={EmailSettingsUiSchema}
          onSubmit={(e: any) => updateEmailSettings(e.formData)}>
          <PrimaryButton type={"submit"}>Speichern</PrimaryButton>
        </Form>
      )}
    </div>
  );
};

export default EmailSettings;
