import React, { FunctionComponent, useState } from "react";
import { Fields, UnlimitedWidgets } from "@webtie-ch/form-widget-library";
import { Spinner } from "react-bootstrap";
import validator from "@rjsf/validator-ajv8";
import { Form } from "@rjsf/bootstrap-4";

import FormSchema from "../../models/schema/FormSchema";
import { PrimaryButton } from "../common/button/Buttons";

interface Props {
  formSchema: FormSchema;
  formData: any;
  onSubmit: (formData: any) => Promise<void>;
}
const RegistrationEditor: FunctionComponent<Props> = ({ formData, formSchema, onSubmit }: Props) => {
  const [isLoading, setLoading] = useState<boolean>(false);

  function submit(formData: any): void {
    setLoading(true);
    onSubmit(formData).finally(() => setLoading(false));
  }

  return (
    <Form
      validator={validator}
      formData={formData}
      schema={formSchema.formSchema}
      uiSchema={formSchema.uiSchema}
      onSubmit={(e: any) => submit(e.formData)}
      widgets={UnlimitedWidgets}
      fields={Fields}>
      <PrimaryButton disabled={isLoading} type={"submit"}>
        Speichern {isLoading && <Spinner size={"sm"} animation={"border"} />}
      </PrimaryButton>
    </Form>
  );
};

export default RegistrationEditor;
