import React, { FunctionComponent } from "react";
import { RouteComponentProps } from "react-router";
import { toast } from "react-toastify";
import { Form } from "@rjsf/bootstrap-4";
import { Helmet } from "react-helmet";
import { Container } from "react-bootstrap";
import validator from "@rjsf/validator-ajv8";

import Limit from "../../models/limit/Limit";
import ButtonRow from "../common/button/ButtonRow";
import { BackButton, PrimaryButton } from "../common/button/Buttons";
import { useLimit } from "../../hooks/useLimit";
import FirebaseService from "../../actions/firebase/FirebaseActions";
import { useAuthentication } from "../../hooks/useAuthentication";
import { updateLimitEditorSchema } from "./UpdateLimitEditorSchema";
import { trackEvent } from "../../helper/analytics";

interface MatchParams {
  userId: string;
  limitId: string;
}
interface Props extends RouteComponentProps<MatchParams> {
  isAuthenticated: boolean;
}
const UpdateLimitEditor: FunctionComponent<Props> = (props: Props) => {
  const userId = props.match.params.userId;
  const limitId = props.match.params.limitId;

  const [limit, limitLoading, limitError] = useLimit(userId, limitId);
  useAuthentication(props.isAuthenticated);

  const saveLimit = (formData: unknown): void => {
    const { limit: formLimit, price: formPrice } = formData as { limit: number; price: number };
    const limit: Partial<Limit> = {
      id: limitId,
      limit: formLimit,
      price: formPrice || 0
    };

    FirebaseService.updateLimit(userId, limit)
      .then(() => {
        toast.success("🎉 Die Änderungen wurden gespeichert");
      })
      .catch((reason) => {
        console.log(reason);
        toast.error(
          "Es ist ein Fehler beim Speichern der Daten aufgetreten. Bitte laden Sie die Applikation erneut und versuchen Sie es noch einmal."
        );
      })
      .finally(() => {
        trackEvent("update-limit");
      });
  };
  if (limitLoading || !limit) return <p>Daten werden geladen ...</p>;
  else if (limitError) return <p>Fehler beim Laden der Daten</p>;
  else {
    return (
      <Container className={"h-100 py-5"}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Spielgruppe Online - Angebot editieren</title>
        </Helmet>
        <ButtonRow>
          <BackButton to={`/user/${userId}/limits`} />
          <h3>{limit.property}</h3>
        </ButtonRow>
        <div className="editor">
          <Form
            validator={validator}
            formData={limit}
            schema={updateLimitEditorSchema}
            onSubmit={(e) => saveLimit(e.formData)}>
            <PrimaryButton type={"submit"}>Speichern</PrimaryButton>
          </Form>
        </div>
      </Container>
    );
  }
};

export default UpdateLimitEditor;
