import { ReleaseDate, WebtieJsonSchema7 } from "@webtie-ch/form-widget-library";
import { JSONSchema7Definition } from "json-schema";
import { UiSchema } from "@rjsf/utils";

export class FormDataToWebtieJsonSchemaMapper {
  constructor(private readonly formData: any) {}

  public mapToJsonSchema = (): WebtieJsonSchema7 => {
    const formFields = this.formData.formFields as [];
    const title = this.formData.title;
    const releaseDate = this.mapReleaseDate(this.formData);
    const requiredFields = this.mapRequiredFields(formFields);
    const fieldsMap = this.mapFormFields(formFields);

    return {
      title: title,
      required: requiredFields,
      releaseDate: releaseDate,
      properties: fieldsMap,
      type: "object"
    };
  };

  public mapToUiSchema = (): UiSchema => {
    const formFields = this.formData.formFields as [];
    const uiSchemaEntries: [any, any][] = formFields.map((formField: any) => {
      const uiSchemaEntryValue = this.mapUiSchemaEntryValue(formField);
      return [formField.title, uiSchemaEntryValue];
    });
    return Object.fromEntries(new Map(uiSchemaEntries));
  };

  private mapFormFields = (
    formFields: []
  ):
    | {
        [key: string]: JSONSchema7Definition;
      }
    | undefined => {
    if (!formFields) {
      return {};
    }
    const fields = formFields.map((value: any) => {
      const entry: [any, any] = [
        [value.title],
        {
          title: value.title,
          type: this.mapFieldType(value.fieldType),
          format: this.mapFieldFormat(value.fieldType),
          description: this.mapDescription(value),
          anyOf: this.mapAnyOf(value)
        }
      ];
      return entry;
    });

    return Object.fromEntries(new Map(fields));
  };

  private mapAnyOf(formField: any):
    | [
        {
          type: string;
          enum: string[];
          title: string;
        }
      ]
    | undefined {
    switch (formField.fieldType) {
      case "select":
        return this.mapSelectAnyOf(formField);
      default:
        return undefined;
    }
  }

  private mapSelectAnyOf(formField: any): [
    {
      type: string;
      enum: string[];
      title: string;
    }
  ] {
    return formField.values.map((value: string) => {
      return {
        type: "string",
        enum: [value],
        title: value
      };
    });
  }

  private mapDescription = (formField: any): string => {
    if (formField.fieldType === "markdown") {
      return formField.markdownDescription;
    }
    return formField.description;
  };

  private mapFieldType = (fieldType: any): string | undefined => {
    switch (fieldType) {
      case "email":
      case "string":
        return "string";
      case "offer":
      case "boolean":
        return "boolean";
      case "integer":
        return "integer";
      case "number":
        return "number";
      case "select":
        return "string";
      case "date":
        return "string";
      case "zipCode":
        return "string";
    }
    return undefined;
  };

  private mapFieldFormat = (fieldType: any): string | undefined => {
    switch (fieldType) {
      case "email":
        return "email";
      default:
        return undefined;
    }
  };

  private mapRequiredFields = (formFields: []): string[] => {
    if (!formFields) {
      return [];
    }
    return formFields.map((value: any) => (value.fieldRequired ? value.title : "")).filter((value) => value !== "");
  };

  private mapUiSchemaEntryValue = (value: any): any => {
    switch (value.fieldType) {
      case "offer":
        return { "ui:widget": "limitedCheckbox" };
      case "markdown":
        return { "ui:field": "markdown" };
      case "title":
        return { "ui:field": "title", "ui:options": { type: value.size } };
      case "date":
        return { "ui:field": "date", "ui:options": { minDate: value.minDate, maxDate: value.maxDate } };
      case "zipCode":
        return { "ui:field": "zipCode", "ui:options": { zipCodes: value.zipCodes } };
    }
    return {};
  };

  private mapReleaseDate(formData: any): ReleaseDate | undefined {
    if (!formData.releaseDate || !formData.releaseDate.dateTime) {
      return undefined;
    }
    return {
      dateTime: formData.releaseDate.dateTime,
      timeZone: formData.releaseDate.timeZone
    };
  }
}
