import React, { FunctionComponent } from "react";
import { toast } from "react-toastify";
import { Form } from "@rjsf/bootstrap-4";
import { Helmet } from "react-helmet";
import validator from "@rjsf/validator-ajv8";

import { UpdateEmailSchema } from "./UpdateEmailSchema";
import { UpdateEmailUiSchema } from "./UpdateEmailUiSchema";
import FirebaseService from "../../../../actions/firebase/FirebaseActions";
import { PrimaryButton } from "../../../common/button/Buttons";

interface Props {
  userId: string;
}
const AccountEmailSettings: FunctionComponent<Props> = () => {
  const updateEmail = (formData: unknown): void => {
    const { currentEmail, currentPassword, newEmail } = formData as {
      currentEmail: string;
      currentPassword: string;
      newEmail: string;
    };

    FirebaseService.updateEmail(currentEmail, currentPassword, newEmail)
      .then(() => {
        toast.success("Die neue Emailadresse wurde erfolgreich gesetzt.");
      })
      .catch((error) => {
        toast.error(`Fehler: ${error}`);
      });
  };
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Webtie Anmeldungen - Profil - Email anpassen</title>
      </Helmet>
      <Form
        validator={validator}
        uiSchema={UpdateEmailUiSchema}
        schema={UpdateEmailSchema}
        onSubmit={(e: any) => updateEmail(e.formData)}>
        <PrimaryButton type={"submit"}>Speichern</PrimaryButton>
      </Form>
    </div>
  );
};

export default AccountEmailSettings;
