import { WebtieJsonSchema7 } from "@webtie-ch/form-widget-library";
import { UiSchema } from "@rjsf/utils";

import FormSchema from "../../models/schema/FormSchema";

export class WebtieJsonSchemaToFormDataMapper {
  private readonly jsonSchema: WebtieJsonSchema7;
  private readonly uiSchema: UiSchema;

  constructor(private readonly formSchema: FormSchema) {
    if (!formSchema.formSchema || !formSchema.uiSchema) {
      throw new Error("FormSchema has undefined members.");
    }
    this.jsonSchema = formSchema.formSchema;
    this.uiSchema = formSchema.uiSchema;
  }

  public mapToFormData = (): any => {
    const title = this.jsonSchema.title;
    const releaseDate = this.jsonSchema.releaseDate;
    const fields = this.mapFields(this.jsonSchema, this.uiSchema);
    const formSettings = this.mapFormSettings(this.formSchema);

    return {
      title: title,
      releaseDate: releaseDate,
      formFields: fields,
      formSettings: formSettings
    };
  };

  private mapFormSettings = (formSchema: FormSchema): any => {
    return {
      emailConfirmationField: formSchema.emailConfirmationField,
      maxOffersToSelect: formSchema.options?.maxOffersToSelect
    };
  };

  private mapFields = (jsonSchema: WebtieJsonSchema7, uiSchema: UiSchema): any[] => {
    const properties = jsonSchema.properties as any;

    return Object.entries(properties).map(([key, value]): [string, any] => {
      const jsonSchemaPropertyEntry = value as any;
      const baseProperties = {
        title: key,
        fieldType: this.mapFieldType(jsonSchemaPropertyEntry),
        fieldRequired: this.mapRequired(key, jsonSchema),
        description: jsonSchemaPropertyEntry?.description,
        values: this.mapValues(jsonSchemaPropertyEntry)
      };
      const uiProperties = this.mapUiFieldProperties(key, uiSchema);
      const mergedProperties = Object.assign(baseProperties, uiProperties);
      const descriptionProperty = this.mapDescription(mergedProperties);
      return Object.assign(mergedProperties, descriptionProperty);
    });
  };

  private mapValues(jsonSchemaPropertyEntry: any): string[] | undefined {
    if (!jsonSchemaPropertyEntry?.anyOf) {
      return undefined;
    }

    const anyOf = jsonSchemaPropertyEntry?.anyOf as { title: string }[];
    return anyOf.map((value) => value.title);
  }

  private mapFieldType(jsonSchemaPropertyEntry: any): string {
    if (jsonSchemaPropertyEntry?.anyOf) {
      return "select";
    }
    return jsonSchemaPropertyEntry?.type ?? undefined;
  }

  private mapDescription(jsonSchemaPropertyEntry: any): { description: string } | { markdownDescription: string } {
    if (jsonSchemaPropertyEntry.fieldType === "markdown") {
      return {
        markdownDescription: jsonSchemaPropertyEntry.description
      };
    }
    return {
      description: jsonSchemaPropertyEntry.description
    };
  }

  private mapRequired(fieldName: string, jsonSchema: WebtieJsonSchema7): boolean {
    return !!jsonSchema?.required?.includes(fieldName);
  }

  private mapUiFieldProperties = (fieldName: string, uiSchema: UiSchema): any => {
    const uiSchemaEntry = Object.entries(uiSchema)
      .filter(([key]) => key === fieldName)
      .pop();
    if (!uiSchemaEntry) {
      return undefined;
    }

    return this.mapFieldEntry(uiSchemaEntry);
  };

  private mapFieldEntry = ([, value]: [string, any]): any => {
    const uiSchemaEntry = Object.entries(value).map(([key, value]: [string, any]) => {
      const uiFieldKey: string = key;
      const uiFieldOption = value;
      switch (uiFieldKey) {
        case "ui:widget": {
          switch (uiFieldOption) {
            case "limitedCheckbox":
              return { fieldType: "offer" };
          }
          return {};
        }
        case "ui:field": {
          switch (uiFieldOption) {
            case "title":
              return {
                fieldType: "title"
              };
            case "markdown":
              return {
                fieldType: "markdown"
              };
            case "date":
              return {
                fieldType: "date"
              };
            case "zipCode":
              return {
                fieldType: "zipCode"
              };
          }
          return {};
        }
        case "ui:options": {
          const uiOptions = Object.entries(uiFieldOption).map(([key, value]) => {
            switch (key) {
              case "type":
                return { size: value };
              case "minDate":
                return { minDate: value };
              case "maxDate":
                return { maxDate: value };
              case "zipCodes":
                return { zipCodes: value };
            }
            return {};
          });
          return uiOptions.reduce((previousValue, currentValue) => Object.assign(previousValue, currentValue), {});
        }
        default:
          return {};
      }
    });
    return (
      uiSchemaEntry &&
      uiSchemaEntry.reduce((previousValue, currentValue) => Object.assign(previousValue, currentValue), {})
    );
  };
}
