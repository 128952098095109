import firebase from "firebase/compat/app";

import Creation from "../../models/registration/Creation";
import Registration from "../../models/registration/Registration";
import Limit from "../../models/limit/Limit";
import { User } from "../../models/user/User";
import { UserData } from "../../models/userData/UserData";
import { RawFormSchema, RawFormSettings } from "../../models/userData/settings/RawFormSettings";
import FormSchema from "../../models/schema/FormSchema";
import { FormSettings } from "../../models/userData/settings/FormSettings";

export class FirebaseSnapshotMapper {
  private constructor() {
    // hide constructor
  }
  public static toRegistration(documentData: firebase.firestore.DocumentData): Registration {
    const registrationData = documentData.data();

    const creation: Creation = {
      createdAt: registrationData?.creation.createdAt.toDate(),
      updatedAt: registrationData?.creation.updatedAt.toDate()
    };

    return {
      id: documentData.id,
      formData: registrationData.formData,
      creation: creation
    };
  }

  public static toRegistrations(querySnapshot: firebase.firestore.QuerySnapshot): Registration[] {
    return querySnapshot.docs.map((file) => this.toRegistration(file));
  }

  public static toLimit(documentData: firebase.firestore.DocumentData): Limit {
    const limitData = documentData.data();

    return {
      id: documentData.id,
      property: limitData.property,
      limit: limitData.limit,
      price: limitData.price,
      taken: limitData.taken
    };
  }

  public static toUserData(documentData: firebase.firestore.DocumentData): UserData {
    const userData = documentData.data();
    return {
      id: documentData.id,
      schemaEndpoint: userData.schemaEndpoint,
      settings: {
        ...userData.settings,
        formSettings: this.mapFormSettings(userData.settings?.formSettings)
      }
    };
  }

  public static toLimits(querySnapshot: firebase.firestore.QuerySnapshot): Limit[] {
    return querySnapshot.docs.map((file) => this.toLimit(file));
  }

  public static toUser(documentData: firebase.firestore.DocumentData): User {
    const userData = documentData.data();

    return {
      id: documentData.id,
      email: userData.email
    };
  }

  public static toUsers(querySnapshot: firebase.firestore.QuerySnapshot): User[] {
    return querySnapshot.docs.map((file) => this.toUser(file));
  }

  private static mapFormSettings(rawFormSettings: RawFormSettings): FormSettings | undefined {
    if (!rawFormSettings) return undefined;
    return {
      ...rawFormSettings,
      formSchema: rawFormSettings?.formSchema ? this.parseSchemas(rawFormSettings.formSchema) : undefined
    };
  }

  private static parseSchemas = (rawFormSchema: RawFormSchema): FormSchema => {
    let formSchema, uiSchema;
    if (rawFormSchema.formSchema) {
      try {
        formSchema = JSON.parse(rawFormSchema.formSchema);
      } catch (_e) {
        throw new Error("FormSchema could not be parsed");
      }
      try {
        uiSchema = JSON.parse(rawFormSchema.uiSchema);
      } catch (_e) {
        throw new Error("UiSchema could not be parsed");
      }
    }
    return {
      formSchema: formSchema,
      uiSchema: uiSchema,
      emailConfirmationField: rawFormSchema.emailConfirmationField,
      options: {
        maxOffersToSelect: rawFormSchema.options?.maxOffersToSelect
      }
    };
  };
}
